<template>
  <div>
    <vs-popup id="delays-by-MAcc" :title="title" :active.sync="open">
      <vs-table
       multiple
      v-model="selected"
      max-items="50"
      :data="response.data"
      >
      <template slot="thead">
        <vs-th>
           Can-ID
        </vs-th>
        <vs-th>Name</vs-th>
        <vs-th style="width: 10%">Company</vs-th>
         <vs-th>Education</vs-th>
        <vs-th>Exp</vs-th>
        <vs-th style="width: 20%">Comments</vs-th>   
        <vs-th>USP Level</vs-th>
        <vs-th>Level</vs-th>
        <vs-th>USP SPOC</vs-th>
        <vs-th v-if="getSubTeam('INT')">City</vs-th>
        <vs-th v-if="getSubTeam('INT')">Country</vs-th>
        <vs-th>SPOC</vs-th>
        <vs-th>SR SPOC</vs-th>
        <!-- <vs-th>First Source </vs-th>
        <vs-th>Last Source </vs-th> -->
        <!-- <vs-th>MHP Date</vs-th> -->
        <vs-th>Last Call</vs-th>
        <vs-th>Next Call</vs-th>
        <vs-th >SR Last Call</vs-th>
        <vs-th >SR Next Call</vs-th>
        <!-- <vs-th v-if="!getTeam('SR')">IIML Last Call</vs-th>
        <vs-th v-if="!getTeam('SR')">IIML Next Call</vs-th> -->
        <vs-th>View Info</vs-th>
      </template>
      <template slot-scope="{ data }">
        <tbody ref="tbody">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div>{{ tr.identity }}</div>
            </vs-td>
            <vs-td :data="tr.email">
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-w="12"
                >
                  <vx-tooltip
                    :title="tr.spoc_name + ' (' + tr.engagement_added_on + ')'"
                    :text="tr.engagement_details"
                    >{{ tr.person_name }}</vx-tooltip
                  >
                </vs-col>
              </vs-row>
            </vs-td>
            <vs-td>
              <div>{{ tr.company }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.education}}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.experience_int }}</div>
            </vs-td>

            <vs-td>
              <div>{{ getComment(tr.engagement_details) }}</div>
            </vs-td>

            <!-- <vs-td v-if="!getrole('exed') && !getrole('exed2')">
              <vs-chip :color="getColor(tr.level)">
                <b>{{ tr.level }}</b>
              </vs-chip>
            </vs-td>

            <vs-td v-else>
              <vs-chip :color="getColor(tr.level)">
                <b>{{ tr.iiml_level }}</b>
              </vs-chip>
            </vs-td>-->
          
            <vs-td>
              <vx-tooltip :text="tr.iiml_level">
                <vs-chip :color="'primary'">
                  <b>{{ getIIMLText(tr.iiml_level) }}</b>
                </vs-chip>
              </vx-tooltip>
            </vs-td>

            <vs-td>
              <vs-chip :color="getColor(tr.level)">
                <b>{{ tr.level }}</b>
              </vs-chip>
            </vs-td>

            <vs-td>
              <div>{{ tr.assigned_iiml_name }}</div>
            </vs-td>

            <vs-td v-if="getSubTeam('INT')">
              <div>{{ tr.international_city }}</div>
            </vs-td>

            <vs-td v-if="getSubTeam('INT')">
              <div>{{ tr.country }}</div>
            </vs-td>

            <vs-td>
              <div>{{ tr.spoc_name }}</div>
            </vs-td>
            <vs-td>
              <div>{{ tr.sr_name }}</div>
            </vs-td>
            <!-- <vs-td>
              <div>{{ tr.source }} <span v-if="tr.source != 'N-DB' && tr.source != 'BTL' && tr.source != 'IVR'" >- {{ tr.source_details }}</span></div>
            </vs-td>
            <vs-td>
              <div v-if='tr.last_source == "Webinar"'>{{ tr.net_enquiry_type }}</div>
              <div v-else>{{ tr.last_source }}</div>
            </vs-td> -->
            <!-- <vs-td>
              <div>{{ unixTimestampConvert(tr.mhp_date) }}</div>
            </vs-td> -->
            <vs-td>
              <div>{{ unixTimestampConvert(tr.iiml_last_call) }}</div>
            </vs-td>
            <vs-td>
              <div>{{ unixTimestampConvert(tr.iiml_next_call) }}</div>
            </vs-td>
            <vs-td >
              <div>{{ unixTimestampConvert(tr.sr_last_call) }}</div>
            </vs-td>
            <vs-td >
              <div>{{ unixTimestampConvert(tr.sr_next_call) }}</div>
            </vs-td>
            <!-- <vs-td v-if="!getTeam('SR')">
              <div>{{ unixTimestampConvert(tr.iiml_last_call) }}</div>
            </vs-td>
            <vs-td v-if="!getTeam('SR')">
              <div>{{ unixTimestampConvert(tr.iiml_next_call) }}</div>
            </vs-td> -->
            <vs-td>
              <vs-row>
                <div @click="openPopupEmit(tr)">
                  <vs-icon
                    icon="info_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>
                <enrollentbutton
                  v-if="getrole('cm1') && !getSubTeam('IIML-SF') || getrole('cm4') || getrole('ac1') || getrole('exed2') || !getSubTeam('IIML-FT') "
                  :lead="tr"
                />
                <IIMLenrollentbutton
                  :lead="tr"
                ></IIMLenrollentbutton>
                <!-- <enrollentbutton :lead="tr" v-if="tr.enrollment_added === 0" />
                <div v-if="tr.enrollment_added === 1">
                  <vs-icon
                    icon="check_circle_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>-->
              </vs-row>
              <vs-row>
                <!-- <div @click="IIMLEnrollmentPopup(tr)">
                  <vs-icon
                    icon="check_circle_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>-->
                <!-- <div @click="IIMLEnrollmentPopup(tr)">
                  <vs-icon icon="check_circle_outline" size="small" color="dark"></vs-icon>
                </div>-->
                <!-- <enrollentbutton v-if="!checkIfRoleis('exed')" :lead="tr" /> -->
                <!-- <enrollentbutton :lead="tr" v-if="tr.enrollment_added === 0" />
                <div v-if="tr.enrollment_added === 1">
                  <vs-icon
                    icon="check_circle_outline"
                    size="small"
                    color="dark"
                  ></vs-icon>
                </div>-->
              </vs-row>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
      </vs-table>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
        <vs-chip color="primary">
          <b>{{ totalCount }}</b>
        </vs-chip>
        <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
      </vs-col>
    </vs-row>
  </vs-popup>
  </div>
</template>

<script>
import EventBus from "../components/eventbus.js";
// import constants from "../../constants.json";
import axios from "axios";
import enrollentbutton from "../components/pagesComponents/buttons/AddEnrollmentButton.vue";
import IIMLenrollentbutton from "../components/pagesComponents/buttons/AddIIMLEnrollmentButton.vue";
export default {
    components: {
    enrollentbutton,
    IIMLenrollentbutton,
    // tooltipInfo
    },
    props: ["open_popup"],
    data() {
    return {
      open: false,
      searchd_obj: {},
      selected:[],
      url: "",
      response: "",
      currentTablePage: 1,
      tablelinks: 0,
      sendingurl: "",
      title: "",
      totalCount: 0,
      IIMLLevelsTooltip:
        // "VH - Very Hot, AR - Application Received, EP - Exam-Pass, EF - Exam-Fail, EPD - Exam-Pass-Dropout, END - Exam-Noshow-Dropout, CMYTC - CM-Yet-To-Connect",
        "VH - Very Hot, AR - Application Received, EP - Exam-Pass, EF - Exam-Fail, EPD - Exam-Pass-Dropout, END - Exam-Noshow-Dropout",
      selectall: false,
      expand: false,
      selectedleads: [],
      notselectedleads: [],
    };
  },
  watch:{
    currentTablePage(value) {
      this.getExedDelausByLevelDetails(value)
    },
  },
  mounted(){
     document.getElementById("delays-by-MAcc").children[1].style.width =
      "80%";
    EventBus.$on("open-delays-MAcc", payload => {
      this.currentTablePage = 1;
      this.searchd_obj = payload[0];
      this.url = payload[1];
      this.response = payload[2];
      this.sendingurl = payload[3];
      this.tablelinks = this.response.last_page;
      this.title = payload[4];
      this.totalCount = payload[2].total;
      console.log("usahsdui iiiiii", this.url, this.searchd_obj);
      this.open = true;
    });
    
  },
  methods:{
    getIIMLText(level) {
      let displayLevel = "";
      switch (level) {
        case "Very Hot":
          displayLevel = "VH";
          break;
        case "Application Received":
          displayLevel = "AR";
          break;
        case "Exam-Pass":
          displayLevel = "EP";
          break;
        case "Exam-Fail":
          displayLevel = "EF";
          break;
        case "Exam-Pass-Dropout":
          displayLevel = "EPD";
          break;
        case "Exam-Fail-Dropout":
          displayLevel = "EFD";
          break;
        case "Exam-Noshow-Dropout":
          displayLevel = "END";
          break;
        // case "CM-Yet-To-Connect":
        //   displayLevel = "CMYTC";
        //   break;
        default:
          displayLevel = level;
      }
      return displayLevel;
    },
    getlastCall(tr) {
      if (this.$route.path === "/myLeads") {
        return tr.last_call;
      } else if (this.$route.path === "/IIMLBussinessLeads") {
        return tr.iiml_last_call;
      } else {
        return tr.last_call;
      }
    },
    getComment(comment){
      if(comment.length > 100){
        const details = comment.slice(0, 100) + "..."
        return details
      }else{
        return comment
      }
    },
    getnextCall(tr) {
      if (this.$route.path === "/myLeads") {
        return tr.next_call;
      } else if (this.$route.path === "/IIMLBussinessLeads") {
        return tr.iiml_next_call;
      } else {
        return tr.next_call;
      }
    },
    sortTable(value) {
      console.log(value);
    },
    selectallFunc() {
      this.selectall = !this.selectall;
    },
     additional(emit, data) {
      this.openPopupEmit(data, emit);
      // EventBus.$emit("select-call-section");
    },
     openPopupEmit(payload) {
      this.openBigPopup(payload.id);
      // console.log("my data: ", payload);
      // let courses = this.getEnrolledCourses(payload);
      // console.log(courses);
      // if (payload.level == "M7" && payload.city == "Hyderabad") {
      //   if (payload.courses.indexOf("CPA") != -1) {
      //     this.openCPAPopup(payload);
      //     return;
      //   } else if (payload.courses.indexOf("CMA") != -1) {
      //     this.openCMAPopupFromMwb(payload);
      //     return;
      //   }
      // }
      // this.openMWBPopup(payload);
    },
    IIMLEnrollmentPopup(tr) {
      EventBus.$emit("open-IIMLEnrollmentPopup", tr);
    },
    getEnrolledCourses(details) {
      let arr = [];
      details.enrollments.forEach((enrollment) => {
        arr.push(enrollment.course);
      });
      return arr;
    },
    addengagement(payload) {
      console.log("emitting");
      EventBus.$emit("open-enrollment-popup", payload);
    },
    fetchbdeName(bdeid) {
      let name = "";
      this.$store.state.bdes.forEach((bde) => {
        if (bde.id === bdeid) {
          name = bde.bdename;
        }
      });
      return name;
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    fetchEngagementDate(latest_engagement) {
      let date = "";
      this.$store.state.engagements.forEach((engagement) => {
        if (engagement.engagement === latest_engagement) {
          date = engagement.engagement_date;
        }
      });
      return date;
    },
    getColor(level) {
      switch (level) {
        case "M1":
          return "danger";
        case "M2":
          return "danger";
        case "M3":
          return "success";
        case "M3+":
          return "success";
        case "M3++":
          return "success";
        case "M4-":
          return "danger";
        case "M4":
          return "danger";
        case "M5":
          return "success";
        case "M6":
          return "success";
        case "L1":
          return "danger";
        case "L2":
          return "warning";
        case "L3":
          return "warning";
        case "L3+":
          return "success";
        case "L4-":
          return "danger";
        case "L4":
          return "warning";
        case "L5":
          return "success";
        case "L6":
          return "success";
        default:
          return "primary";
      }
    },
    engagementlast(engagements) {
      return engagements.slice(-1)[0];
    },
    getExedDelausByLevelDetails(page){
      this.$vs.loading();
      let url = this.url + `?page=${page}`
      console.log(page,url)
        axios
          .get(url, {
            params:this.searchd_obj,
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
          })
          .then(response => {
            // this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
            this.response = response.data
            this.$vs.loading.close();
          })
          .catch(error => {
            this.$vs.loading();
            this.handleError(error);
          });
    }
  }
}
</script>

<style>

</style>