<template>
  <div>
    <vs-table :data="tableRows" style="margin-top: 3%; border-top: 1px solid; border-left: 1px solid; color: black" max-height="500px">
      <template slot="thead">
        <vs-th :key="index" v-for="(head, index) in tableHeaders" :colspan="head.colspan" style="border-right: 1px solid; position: sticky;
                                  top: 0px; background-color: white;"
          :class="{ fixed_columns_section: head.header == 'OverAll', fixed_header_level_section: head.header == 'Level' }">{{
            head.header }}</vs-th>
      </template>
      <template slot-scope=" { data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" :style="tr.style" :class="indextr === 0 ? 'fixed_first_row_section' : ''">
          <vs-td class="fixed_columns_section" :style="tr.style">{{ tr.overall }}</vs-td>
          <vs-td v-if="tr.level == 'blank_u_level'" class="fixed_header_level_section" :style="tr.style">Blank U Level</vs-td>
          <vs-td v-else class="fixed_header_level_section" :style="tr.style">{{ tr.level }}</vs-td>
          
          <vs-td v-if="tr.city.today != 'Today'" style="background-color: #d9ead3; border: 1px solid;">
            <div @click="
              countDeails(
                tr.level === '' ? tr.overall : tr.level,
                selected_city,
                'today',
                selected_city_classification,
              )
            ">
              {{ tr.city.today }}
            </div>
          </vs-td>
          <vs-td v-else style="background-color: #d9ead3; border: 1px solid;">
            <div>
              {{ tr.city.today }}
            </div>
          </vs-td>

          <vs-td v-if="tr.city.tomorrow != 'Tomorrow'" style="background-color: #cfe2f3; border: 1px solid;">
            <div @click="
              countDeails(
                tr.level === '' ? tr.overall : tr.level,
                selected_city,
                'tomorrow',
                selected_city_classification,

              )
            ">
              {{ tr.city.tomorrow }}
            </div>
          </vs-td>
          <vs-td v-else style="background-color: #cfe2f3; border: 1px solid;">
            <div>
              {{ tr.city.tomorrow }}
            </div>
          </vs-td>

          <vs-td v-if="tr.city.future != 'Future'" style="background-color: #ead1dc; border: 1px solid;">
            <div @click="
              countDeails(
                tr.level === '' ? tr.overall : tr.level,
                selected_city,
                'future',
                selected_city_classification,
              )
            ">
              {{ tr.city.future }}
            </div>
          </vs-td>
          <vs-td v-else style="background-color: #ead1dc; border: 1px solid;">
            <div>
              {{ tr.city.future }}
            </div>
          </vs-td>

          <vs-td v-if="tr.city.past != 'Past'" style="background-color: #fff2cc; border: 1px solid;">
            <div @click="
              countDeails(
                tr.level === '' ? tr.overall : tr.level,
                selected_city,
                'past',
                selected_city_classification,

              )
            ">
              {{ tr.city.past }}
            </div>
          </vs-td>
          <vs-td v-else style="background-color: #fff2cc; border: 1px solid;">
            <div>
              {{ tr.city.past }}
            </div>
          </vs-td>

          
          <!-- <vs-td v-for="(spoc, spocIndex) in tr.dynamicSpoc" :key="spocIndex" :style="[spoc == 'Today' ? {'background-color': '#d9ead3'} : {'background-color': '#d9ead3', 'border': '1px solid'} && spoc == 'Tomorrow' ? {'background-color': '#cfe2f3'} : {'background-color': '#cfe2f3', 'border': '1px solid'} && spoc == 'Future' ? {'background-color': '#ead1dc'} : {'background-color': '#ead1dc', 'border': '1px solid'} && spoc == 'Past' ? {'background-color': '#fff2cc'} : {'background-color': '#fff2cc', 'border': '1px solid'}]"> -->
          <vs-td v-for="(spoc, spocIndex) in tr.dynamicSpoc" :key="spocIndex" :style="getStyleObject(spocIndex, tr.dynamicSpoc)">
            <div v-if="spoc != 'Today' && spoc != 'Tomorrow' && spoc != 'Future' && spoc != 'Past'" @click="
              countDeails(
                tr.level === '' ? tr.overall : tr.level,
                [tr, spocIndex],
                data[0].dynamicSpoc[spocIndex],
                selected_city_classification,

              )
            ">
              {{ spoc }}
            </div>
            <div v-else>
              {{ spoc }}
            </div>
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
  
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import EventBus from "../components/eventbus";
import DelaysMAccPopup from "./DelaysMAccPopup.vue";
export default {
  mounted() {
    EventBus.$on("get-ulevel-data", (response, cc, city, spoc_names) => {
            this.selected_city_classification = cc;
      this.selected_city = city;
      this.spoc_names = spoc_names;
      this.generateDataTable(response);
    });

  },
  components: {
    Datepicker,
    "v-select": vSelect,
    DelaysMAccPopup,
  },
  watch: {
    dateRange() {
      this.getMAccLevelsCount();
    },
  },
  data() {
    return {
      dateRange: new Date(),
      MwbLeads: [],
      totalCount: 0,
      open: false,
      level: "",
      placement_data: "",
      type_data: "",
      selected_cc: "",
      title: "",
      currentTablePage: 1,
      tablelinks: 1,
      spoc_names: [],
      selected_city_classification: "",
      selected_city: "",
      macc_id: [],
      tableRows: [],
      tableHeaders: [],
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
      IIMLLevelsTooltip:
        // "VH - Very Hot, AR - Application Received, EP - Exam-Pass, EF - Exam-Fail, EPD - Exam-Pass-Dropout, END - Exam-Noshow-Dropout, CMYTC - CM-Yet-To-Connect",
        "VH - Very Hot, AR - Application Received, EP - Exam-Pass, EF - Exam-Fail, EPD - Exam-Pass-Dropout, END - Exam-Noshow-Dropout",
      selectall: false,
      expand: false,
      selectedleads: [],
      notselectedleads: [],
      tablerowULevelConstant: [
        {
          style: { borderTop: "1px solid", borderBottom: "1px solid", background: "#ffffff" },
          overall: "",
          level: ""
        },
        {
          style: { borderBottom: "1px solid", background: "#FFCCCC" },
          overall: "Total",
          level: ""
        },
        {
          style: { background: "#ccffcc" },
          level: "blank_u_level",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U0",
          rowspan: 4,
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U1",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U1+",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U2",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U3",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U3+",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U3++",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U3-",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U4",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U4R",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U4-",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U5",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U5+",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U6",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U6-",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U6+",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U7",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U7-",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U9",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "U10",
          type: "P1"
        },
        {
          style: { background: "#ccffcc" },
          level: "NE",
          type: "P1"
        },
        {
          style: { borderTop: "1px solid", background: "#FFCCCC", borderBottom: "1px solid" },
          overall: "Total",
          level: ""
        },
      ],
    };
  },
  methods: {
    getStyleObject(spocIndex, total_spocs) {
      let arr_of_indexes = []
      for (let j = 0; j < total_spocs.length; j++) {
        arr_of_indexes.push(j);
      }
      for (let i = 0; i < arr_of_indexes.length; i += 4) {
        if(spocIndex === arr_of_indexes[i]){
          return {
          backgroundColor: '#d9ead3',
          border: '1px solid'
          };
        }  
      }
      for (let i = 1; i < arr_of_indexes.length; i += 4) {
        if(spocIndex === arr_of_indexes[i]){
          return {
          backgroundColor: '#cfe2f3',
          border: '1px solid'
          };
        }  
      }
      for (let i = 2; i < arr_of_indexes.length; i += 4) {
        if(spocIndex === arr_of_indexes[i]){
          return {
          backgroundColor: '#ead1dc',
          border: '1px solid'
          };
        }  
      }
      for (let i = 3; i < arr_of_indexes.length; i += 4) {
        if(spocIndex === arr_of_indexes[i]){
          return {
          backgroundColor: '#fff2cc',
          border: '1px solid'
          };
        }  
      }
    },
    generateDataTable(response) {
      this.tableRows = this.tablerowULevelConstant;
      this.generateTableHeaders(response);
      this.generateTableRows(response);
    },
    generateTableHeaders(response) {
      this.tableHeaders = [];
      this.tableHeaders.push({
        header: "OverAll",
        colspan: 1,
        fixed: true,
      });
      this.tableHeaders.push({
        header: "Level",
        colspan: 1,
        fixed: true,
      });
      if(this.selected_city != 'Pan India'){
      let city_code = this.selected_city_classification;
      let city = this.selected_city.concat(" ", "(", city_code, ")");
      this.tableHeaders.push({
        header: city,
        colspan: 4,
      });
      response.forEach((cityData) => {
        let obj = {
          header: cityData.spoc_name,
          colspan: 4,
        };
        this.tableHeaders.push(obj);
      });
    }else {
        this.tableHeaders.push({
          header: '',
          colspan: 4,
        });
        response.forEach((cityData) => {
        let obj = {
          header: cityData.city,
          colspan: 4,
        };
        this.tableHeaders.push(obj);
      });
      }
      // response.forEach((cityData) => {
      //   let obj = {
      //     header: cityData.spoc_name,
      //     colspan: 2,
      //   };
      //   this.tableHeaders.push(obj);
      // });
    },
    generateTableRows(response) {
      this.tableRows[0].dynamicSpoc = [];
      if(this.selected_city != 'Pan India'){
      this.tableRows[0].city = {
        today: "Today",
        tomorrow: "Tomorrow",
        future: "Future",
        past: "Past",
      };
      }else{
        this.tableRows[0].city = {}
      }
      this.tableRows.forEach((row, index) => {
        if (index !== 0) {
          row.dynamicSpoc = [];
          if(this.selected_city != 'Pan India'){
          row.city = {
            today: 0,
            tomorrow: 0,
            future: 0,
            past: 0,
          };
        }else{
          row.city = {};
        }
      }
      });
      response.forEach(() => {
        let today = 0;
        let tomorrow = 0;
        let future = 0;
        let past = 0;
        this.tableRows[0].dynamicSpoc.push("Today");
        this.tableRows[0].dynamicSpoc.push("Tomorrow");
        this.tableRows[0].dynamicSpoc.push("Future");
        this.tableRows[0].dynamicSpoc.push("Past");
        this.tableRows.forEach((row, index) => {
          if (index !== 0) {
            row.dynamicSpoc.push(today);
            row.dynamicSpoc.push(tomorrow);
            row.dynamicSpoc.push(future);
            row.dynamicSpoc.push(past);
          }
        });
      });
      this.InitialCalculation(response);
    },
    InitialCalculation(response) {
      this.tableRows.forEach((row, index) => {

        if (index > 1 && index !== this.tableRows.length - 1) {
          let city_all = 0;
          let city_today = 0;
          let city_tomorrow = 0;
          let city_future = 0;
          row.dynamicSpoc = [];
          response.forEach(spoc => {
            for (var key in spoc) {

              if (spoc.hasOwnProperty(key)) {
                if (key !== "NA" && key !== "spoc_id" && key !== "spoc_name") {
                  for (var key2 in spoc[key]) {
                    if (spoc[key].hasOwnProperty(key2)) {
                      if (key2 === row.level) {
                        //city total column calculation
                        city_today = city_today + spoc[key][key2].today;
                        city_tomorrow = city_tomorrow + spoc[key][key2].tomorrow;
                        city_future = city_future + spoc[key][key2].future;
                        city_all = city_all + spoc[key][key2].past;
                        //individual calculation
                        row.dynamicSpoc.push(spoc[key][key2].today);
                        row.dynamicSpoc.push(spoc[key][key2].tomorrow);
                        row.dynamicSpoc.push(spoc[key][key2].future);
                        row.dynamicSpoc.push(spoc[key][key2].past);
                      }
                    }
                  }
                }
              }
            }
          });
          //city total column calculation
          if(this.selected_city != 'Pan India'){
          row.city.past= city_all
          row.city.today= city_today
          row.city.tomorrow= city_tomorrow
          row.city.future= city_future
          }
        }
      });
      let total_city_all = 0;
      let total_city_today = 0;
      let total_city_tomorrow = 0;
      let total_city_future = 0;
      let dynamicarr = [];
      this.tableRows.forEach((row, index) => {
        if (index === 1) {
          dynamicarr = row.dynamicSpoc;
        } else if (index > 1) {
          total_city_today = total_city_today + row.city.today;
          total_city_tomorrow = total_city_tomorrow + row.city.tomorrow;
          total_city_future = total_city_future + row.city.future;
          total_city_all = total_city_all + row.city.past;
          row.dynamicSpoc.forEach((spocStat, index2) => {
            dynamicarr[index2] = parseInt(spocStat);
          });
        }
      });
      this.tableRows[1].city.today = total_city_today;
      this.tableRows[1].city.tomorrow = total_city_tomorrow;
      this.tableRows[1].city.future = total_city_future;
      this.tableRows[1].city.past = total_city_all;
      this.tableRows[1].dynamicSpoc = dynamicarr;
      this.calculateM7(response);
      // this.calculateTotals();
      let last_row = this.tableRows.length - 1;
      if(this.selected_city != 'Pan India'){
      this.tableRows[last_row].city.today = total_city_today;
      this.tableRows[last_row].city.tomorrow = total_city_tomorrow;
      this.tableRows[last_row].city.future = total_city_future;
      this.tableRows[last_row].city.past = total_city_all;
      }else{
        this.tableRows[last_row].city = {}
      }
      this.tableRows[last_row].city.dynamicSpoc = dynamicarr;
      this.$vs.loading.close();
    },
    calculateM7(response) {
      let total_city_all = 0;
      let total_city_today = 0;
      let total_city_tomorrow = 0;
      let total_city_future = 0;
      let dynamicarr = [];
      this.tableRows.forEach((row, index) => {
        if (index === 1) {
          dynamicarr = row.dynamicSpoc;
        } else if (index > 1) {
          total_city_today = total_city_today + row.city.today;
          total_city_tomorrow = total_city_tomorrow + row.city.tomorrow;
          total_city_future = total_city_future + row.city.future;
          total_city_all = total_city_all + row.city.past;
          row.dynamicSpoc.forEach((spocStat, index2) => {
            dynamicarr[index2] =
              parseInt(dynamicarr[index2]) + parseInt(spocStat);
          });
        }
      });
      this.tableRows[1].city.today = total_city_today;
      this.tableRows[1].city.tomorrow = total_city_tomorrow;
      this.tableRows[1].city.future = total_city_future;
      this.tableRows[1].city.past = total_city_all;
      this.tableRows[1].dynamicSpoc = dynamicarr;
      let last_row = this.tableRows.length - 1;
      if(this.selected_city != 'Pan India'){
      this.tableRows[last_row].city.today = total_city_today;
      this.tableRows[last_row].city.tomorrow = total_city_tomorrow;
      this.tableRows[last_row].city.future = total_city_future;
      this.tableRows[last_row].city.past = total_city_all;
      }else{
        this.tableRows[last_row].city = {}
      }
      this.tableRows[last_row].dynamicSpoc = dynamicarr;
      console.log(response)
    },
    calculateTotals() {
      let parts = ["P1", "P2", "P3"];
      parts.forEach(eachPart => {
        let cityAll = 0;
        let cityToday = 0;
        let cityTomorrow = 0;
        let cityFuture = 0;
        let dynamicSpocValues = [];
        this.tableRows[0].dynamicSpoc.forEach(() => {
          dynamicSpocValues.push(0);
        });
        this.tableRows.forEach(tableRow => {
          if(this.selected_city != 'Pan India'){
          if (tableRow.type === eachPart) {
            cityToday = parseInt(cityToday) + parseInt(tableRow.city.today);
            cityTomorrow = parseInt(cityTomorrow) + parseInt(tableRow.city.tomorrow);
            cityFuture = parseInt(cityFuture) + parseInt(tableRow.city.future);
            cityAll = parseInt(cityAll) + parseInt(tableRow.city.past);
            tableRow.dynamicSpoc.forEach((value, index) => {
              dynamicSpocValues[index] =
                parseInt(dynamicSpocValues[index]) + parseInt(value);
            });
          } else if (tableRow.type === `${eachPart} Total`) {
            tableRow.city.today = cityToday;
            tableRow.city.tomorrow = cityTomorrow;
            tableRow.city.future = cityFuture;
            tableRow.city.past = cityAll;
            tableRow.dynamicSpoc = dynamicSpocValues;
          }
        }else{
          tableRow.city = {}
          if (tableRow.type === eachPart) {
            tableRow.dynamicSpoc.forEach((value, index) => {
              dynamicSpocValues[index] =
                parseInt(dynamicSpocValues[index]) + parseInt(value);
            });
          }
          if (tableRow.type === `${eachPart} Total`) {
            tableRow.dynamicSpoc = dynamicSpocValues;
          }
        }
        });
      });
    },
    mapSearchSpoc(placement) {
      let obj = placement[0];
      let objindex = placement[1];
      let objlength = obj.dynamicSpoc.length / 4;
      let mapObj = [];
      for (let i = 1; i <= objlength; i++) {
        mapObj.push({
          sequence: i,
          data: []
        });
      }
      let count = 0;
      let map = 0;
      let mapname = 0;
      obj.dynamicSpoc.forEach((spocstat, index) => {
        if (count === 4) {
          count = 0;
          map = map + 1;
        }
        mapObj[map].data.push(spocstat);
        if (index === objindex) {
          mapname = map;
        }
        count = count + 1;
      });
      let spoc = this.foundspoc(mapname);
      return spoc;
    },
    foundspoc(spocindex) {
      let spocarr = [];
      this.tableHeaders.forEach((header, index) => {
        if (index > 2) {
          spocarr.push(header);
        }
      });
      return spocarr[spocindex].header;
    },
    convertType(type) {
      let newtype = "";
      newtype = type.toLowerCase();
      return newtype;
    },
    countDeails(level, placement, type, selected_city_classification) {
      let city_classification = selected_city_classification;
      if (type === "Today" || type === "Tomorrow" || type === "Future" || type === "Past") {
        let newtype = this.convertType(type);
        let spocname = this.mapSearchSpoc(placement);
        this.getDetailsData(level, spocname, newtype, city_classification);
      } else {
        this.getDetailsData(level, placement, type, city_classification);
      }
    },
    getDetailsData(level, placement, type, city_classification) {
      console.log(placement);
      // this.title = `${level} - ${placement} - ${type}`;
      let popupName = '';
      if (level == 'blank_u_level') {
        popupName = `Blank U Level - ${placement.toUpperCase()} - ${type.toUpperCase()}`;
      }else {
        popupName = `${level} - ${placement.toUpperCase()} - ${type.toUpperCase()}`;
      }

      this.spoc_names.forEach((spoc) => {
        if (spoc.full_name === placement) {
          placement = spoc.id;
          this.placement_data = placement;
        }
      });
      if (this.dateRange === "") {
        this.$vs.notify({
          title: "Select a Date",
          text: "Select a date to continue using this feature",
          color: "warning",
        });
      } else {
        this.$vs.loading();
        let url = `${constants.SERVER_API}getVisitSchedulesContactDetails`;
        // let url = `https://crm.milesforce.com/api/getVisitSchedulesContactDetails`;
        let obj = {
          selected_date: this.datatoTimestamp(this.dateRange),
          level: level,
          type: type,
          spoc_id: "",
          city: "",
          city_classification: "",
          selected_level_type: 'u_level',
        };

        if (Number.isInteger(placement)) {
          obj.spoc_id = placement;
        } else {
          obj.city = placement;
          obj.city_classification = city_classification;
        }
        if (obj.level === "Total") {
          obj.level = "U";
        }
        console.log("finsskjkdjs", obj)
        axios
          .get(url, {params: obj,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log("getVisitSchedulesContactDetails", response);
            response.data.data.forEach((lead) => {
              lead.engagements = [];
              lead.mobile = [];
              lead.email = [];
            });
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);

            let popupcount = response.data.total;
            console.log("counnt", popupcount);
            EventBus.$emit("open-coverage-details", [
              obj,
              url,
              response.data,
              "getVisitSchedulesContactDetails",
              popupName + "-" + popupcount,
            ]);
            this.$vs.loading.close();
          })
          .catch((error) => {
            this.handleError(error);
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>
  
<style scoped>
  .vs--searchable .vs__dropdown-toggle {
  height: 38px;
}

.fixed_columns_section {
  left: 0px;
  position: sticky;
  z-index: 100;
  background: white;
}

.fixed_header_level_section {
  left: 60px;
  position: sticky;
  z-index: 100;
  width: 100px;
}
.fixed_first_row_section {
  position: sticky;
  top: 50px;
  background-color: white;
  z-index: 1000000;
}
</style>